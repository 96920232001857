<template>
  <div class="p_blance">
    <el-collapse v-model="activeNames" @change="handleChange">

      <div class="my__collapse">
          <el-collapse-item name="1">
        <template slot="title">
          <div class="title_blockss d-flex align-items-center w-100">
            <div class="title_blockss_text" style="flex: 1;">
              <div class="titlecallapes_itme">Все Услуга Пациента</div>
              <span class="summa_info_userlist ml-4">Общая сумма: {{ total_mount | formatMoney }} сум</span>
            </div>
            
          </div>
        </template>
        <div class="collapse_body">
          <div class="table_servis_list">
            <table class="table mt-4" v-loading="loadingData">
              <thead>
                <tr>
                  <th class="wt5">П.П.</th>
                  <th>Наименование</th>
                  <th class="wt7">Кол во</th>
                  <th class="wt10">Дата</th>
                  <th class="wt10"> Цена</th>
                  <th class="wt7">Скидка %</th>
                  <th class="wt10">Скидка (Сумм)</th>
                  <th class="wt10">Сумма</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ser,index) in list" :key="'order-create-in-header'+index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ ser.name }}
                  </td>
                  <td>
                    {{ ser.count }}
                  </td>
                  <td>
                    {{ ser.created_at }}
                  </td>
                  <td>
                    {{ ser.price | formatMoney }}
                  </td>
                  <td>
                    {{ ser.discount}}
                  </td>
                  <td>
                    {{ ser.price * ser.count - ser.total_price | formatMoney}}
                  </td>
                  <td>
                    {{ ser.total_price | formatMoney}}
                  </td>
                </tr>
              </tbody>
            </table>            
          </div>
        </div>
          </el-collapse-item>
          <el-button class="mr-2 pos_aps"  icon="el-icon-printer" type="primary" @click="print('patientBalance/servicePrint', selected ? selected : '')" round>Распечатать</el-button>
      </div>
     
      <!-- end  el-collapse-item-->

      <div class="my__collapse">
        <el-collapse-item name="2">
          <template slot="title">
            <div class="title_blockss d-flex align-items-center w-100">
              <div class="title_blockss_text" style="flex: 1;">
                <div class="titlecallapes_itme">Лекарственные средства</div>
                <span class="summa_info_userlist ml-4">Общая сумма: {{ medcineAmount | formatMoney }} сум</span>
              </div>
              
            </div>
          </template>
          <div class="collapse_body">
            <div class="table_servis_list">
              <table class="table mt-4" v-loading="loadingData">
                <thead>
                  <tr>
                    <th scope="col" class="wt5">П.П.</th>
                    <th scope="col">Наименование</th>
                    <th scope="col" class="wt7">Кол во</th>
                    <th scope="col" class="wt10">Дата</th>
                    <th scope="col" class="wt10">Цена</th>
                    <th scope="col" class="wt10">Сумма</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ser,index) in medcine" :key="'order-create'+index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ ser.product.name }}
                    </td>
                    <td>
                      {{ ser.total_quantity }}
                    </td>
                    <td>
                      {{ ser.last_date }}
                    </td>
                    <td>
                      {{ ser.product.price | formatMoney }}
                    </td>
                    <td>
                      {{ ser.total_price | formatMoney}}
                    </td>
                  </tr>
                </tbody>
              </table>  
            </div>
          </div>
        </el-collapse-item>
        <el-button class="mr-2 pos_aps" icon="el-icon-printer" type="primary" @click="print('patientBalance/treatmentPrint', selected ? selected : '')" round>Распечатать</el-button>
      </div>
      
      <!-- end  el-collapse-item-->

      <div class="my__collapse">
        <el-collapse-item name="3">
          <template slot="title">
            <div class="title_blockss d-flex align-items-center w-100">
              <div class="title_blockss_text" style="flex: 1;">
                <div class="titlecallapes_itme">Физиотерапия</div>
                <span class="summa_info_userlist ml-4">Общая сумма: {{ therapyAmount | formatMoney }} сум</span>
              </div>
              
            </div>
          </template>
          <div class="collapse_body">
            <div class="table_servis_list">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" class="wt5">П.П.</th>
                    <th scope="col">Наименование</th>
                    <th scope="col" class="wt10">Кол во</th>
                    <th scope="col" class="wt10">Дата</th>
                    <th scope="col" class="wt10">Цена</th>
                    <th scope="col" class="wt10">Сумма</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ser,index) in therapy" :key="'order-create'+index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ ser.service.name }}
                    </td>
                    <td>
                      {{ ser.total_quantity }}
                    </td>
                    <td>
                      {{ ser.last_date }}
                    </td>
                    <td>
                      {{ ser.service.price  | formatMoney }}
                    </td>
                    <td>
                      {{ ser.total_price | formatMoney}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-collapse-item>
        <el-button class="mr-2 pos_aps" icon="el-icon-printer" type="primary" @click="print('patientBalance/therapyPrint', selected ? selected : '')" round>Распечатать</el-button>
      </div>
      <!-- end  el-collapse-item-->

      <div class="my__collapse">
        <el-collapse-item name="4">
          <template slot="title">
            <div class="title_blockss d-flex align-items-center w-100">
              <div class="title_blockss_text" style="flex: 1;">
                <div class="titlecallapes_itme">Комната</div>
                <span class="summa_info_userlist ml-4">Общая сумма: {{ classsAmount | formatMoney }} сум</span>
              </div>
            </div>
          </template>
          <div class="collapse_body">
            <div class="table_servis_list">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">П.П.</th>
                    <th scope="col">Класс</th>
                    <th scope="col">Комната</th>
                    <th scope="col">Сколько дней</th>
                    <th scope="col">Цена на день</th>
                    <th scope="col">Сумма</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ser,index) in classs" :key="'order-create'+index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ ser.class }}
                    </td>
                    <td>
                      {{ ser.room }}
                    </td>
                    <td>
                      {{ ser.days }}
                    </td>
                    <td>
                      {{ ser.price_per_day | formatMoney}}
                    </td>
                    <td>
                      {{ ser.total_price | formatMoney}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-collapse-item>
        <el-button class="mr-2 pos_aps" icon="el-icon-printer" type="primary" @click="print('patientBalance/bunkPrint', selected ? selected : '')" round >Распечатать</el-button>    
      </div>
      <!-- end  el-collapse-item-->
    </el-collapse>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import print from '@/utils/mixins/print';
export default {
  mixins: [print],
  props: ['selected'],
  data() {
    return {
      loadingData: false,
      activeNames: [""]
    };
  },
  computed: {
    ...mapGetters({
      list: 'hospitalizationServices/list',
      total_mount: 'hospitalizationServices/total_amount',
      medcine: 'patientBalances/medcine',
      medcineAmount: 'patientBalances/total_amount',
      therapy: 'patientBalances/therapy',
      therapyAmount: 'patientBalances/therapy_amount',
      classs: 'patientBalances/classs',
      classsAmount: 'patientBalances/classs_amount',
    }),
    actions: function () {
      return ['edit', 'delete'];
    }
  },
  watch: {
    selected: {
      handler: function (val, oldVal) {
        if (this.selected && !this.loadingData) {
        this.loadingData = true;
        this.editModel({
            hospital_patient_id: null,
            id: this.selected
          }).then(res => {
          }).catch(err => {
            this.loadingData = false;
            this.$alert(err)
          });
          this.getMedcine({
            hospital_patient_id: null,
            id: this.selected
          }).then(res => {
          }).catch(err => {
            this.loadingData = false;
            this.$alert(err)
          });
          this.getTherapy({
            hospital_patient_id: this.getId,
            id: this.selected
          }).then(res => {
            this.loadingData = false;
          }).catch(err => {
            this.loadingData = false;
            this.$alert(err)
          });
          this.getClasss({
            hospital_patient_id: this.getId,
            id: this.selected
          })
          .then(res => {
            this.loadingData = false;
          }).catch(err => {
            this.loadingData = false;
            this.$alert(err)
          });
        }
      }
    }
  },
  methods: {
    ...mapActions({
      editModel: 'hospitalizationServices/indexitems',
      emptyH: 'hospitalizationServices/empty',
      getMedcine: 'patientBalances/indexitems',
      getTherapy: 'patientBalances/indextherapy',
      getClasss: 'patientBalances/indexclasss',
      emptyModel: 'patientBalances/empty',
    }),
    empty() {
      this.emptyModel()
      this.emptyH()
    },
    handleChange(val) {
    
    }
  },
};
</script>
<style lang="scss">
.title_blockss_text {
  font-weight: 700;
}
.summa_info_userlist {
  color: #007bff;
  font-weight: 300;
}
.collapse_body {
  .table_servis_list {
    padding: 0px;
  }
}
.my__collapse{
    position: relative;
}
.pos_aps{
      position: absolute;
    top: 9px;
    right: 30px;
}
.collapse_body .table_servis_list .wt5 {
  width: 5%;
}
.collapse_body .table_servis_list .wt7 {
  width: 5%;
}
.collapse_body .table_servis_list .wt10 {
  width: 12%;
}
.titlecallapes_itme {
  min-width: 180px;
  display: inline-block;
}
</style>