<template>
  <div class="papel_user">
    <div class="table_servis_list">
       <table class="table mt-4" v-loading="loadingData">
          <thead>
            <tr>
              <th class="wt5">П.П.</th>
              <th>Тип оплаты</th>
              <th class="wt7">Сумма</th>
              <th class="wt10">Валюта</th>
              <th class="wt10">Курс</th>
              <th class="wt7">Дата</th>
              <th class="wt10">Комментарий</th>
              <th class="wt10">Ползаватель</th>
              <th class="wt10">Настройка</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ser,index) in list" :key="'order-create-in-header'+index">
              <td>{{ index + 1 }}</td>
              <td>
                {{ ser.payment_type }}
              </td>
              <td>
                {{ ser.amount | formatMoney}}
              </td>
              <td>
                {{ ser.currency }}
              </td>
              <td>
                {{ ser.rate  }}
              </td>
              <td>
                {{ ser.created_at}}
              </td>
              <td>
                {{ ser.comment }}
              </td>
              <td>
                {{ ser.user.name}} {{ ser.user.surname}}
              </td>
              <td>
                <el-button type="success" @click="returnBack(ser)" icon="el-icon-refresh-right" size="small" plain>Возврат</el-button>
              </td>
            </tr>
          </tbody>
        </table> 
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ['selected'],
  data() {
    return {
      loadingData: false,
      activeNames: [""]
    };
  },
  computed: {
    ...mapGetters({
      list: 'patientTransactions/list',
    }),
    actions: function () {
      return ['edit', 'delete'];
    }
  },
  watch: {
    selected: {
      handler: function (val, oldVal) {
        if (this.selected && !this.loadingData) {
        this.loadingData = true;
        this.editModel({
            hospital_patient_id: null,
            id: this.selected
          })
          .then(res => {
            this.loadingData = false;
          }).catch(err => {
            this.loadingData = false;
            this.$alert(err)
          });
        }
      }
    }
  },
  async mounted() {
    var vm = this;
    if (this.selected && !this.loadingData) {
    this.loadingData = true;
    this.editModel({
        hospital_patient_id: null,
        id: this.selected
      })
      .then(res => {
        this.loadingData = false;
      }).catch(err => {
        this.loadingData = false;
        this.$alert(err)
      });
    }
  },
  methods: {
    ...mapActions({
      editModel: 'patientTransactions/indexitems',
      returnTo: 'patientTransactions/returnBack',
    }),
    handleChange(val) {
    
    },
    returnBack(model) {
        this.$confirm(
          "Вы действительно хотите это сделать?",
          "Предупреждение",
          {
            confirmButtonText: "Да",
            cancelButtonText: "Отмен",
            type: "warning"
          }
        ).then(() => {
            this.loadingData = true;
            this.returnTo(model.id)
              .then(res => {
                this.fetchAgain();
                this.$emit('rollback')
                this.$emit('roll', model)
              }).catch(err => {
                this.loadingData = false;
                this.$alert(err)
              });
          })
          .catch(() => {
            this.$message({
              type: "warning",
              message: "Операция отменена"
            });
          });
    },
    fetchAgain() {
        this.loadingData = true;
        this.editModel({
            hospital_patient_id: null,
            id: this.selected
          })
          .then(res => {
            this.loadingData = false;
          }).catch(err => {
            this.loadingData = false;
            this.$alert(err)
          });
    }
  },
};
</script>
<style lang="scss">
.papel_user .table_servis_list .wt10 {
  width: 12%;
}
.papel_user {
  .table_servis_list {
    padding: 0px;
  }
}
</style>